<template>
  <div class="content-editor">
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      class="content-editor__editor"
    />
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from 'tiptap-vuetify'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // starting editor's content
      content: this.value,
    }
  },
  watch: {
    value(v) {
      this.content = v
    },
    content(value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .content-editor {
  &__editor {
    .tiptap-vuetify-editor__content {
      max-height: 500px;
      background-color: #f1f3f4;
    }
  }
}

::v-deep .v-card.v-sheet {
  box-shadow: none !important;
}
</style>
